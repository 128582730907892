<template>
  <div class="home_card flex justify-between items-center p-8">
    <div class="flex h-full flex-col items-start justify-between">
      <p class="card_title">
        {{ title }}
      </p>
      <button
        @click="RouteTo(link)"
        class="card_button mt-6"
      >
        {{ buttonText }}
      </button>
    </div>
    <img
      :src="img"
      alt=""
    >
  </div>
</template>

<script>
export default {
  props: {
    title: {type: String, required: true},
    link: {type: String, required: true},
    img: {type: String, required: true},
    buttonText: {type: String, required: true}
  },
  methods: {
    RouteTo(x) {
      this.$router.push({path: x});
    }
  }
};
</script>

<style scoped>
.home_card {
  background-color: white;
  min-height: 156px;
  border-radius: 15px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
}

.content p:first-child {
  font-weight: 700;
  font-size: 24px;
}

.content p:last-child {
  font-weight: 500;
  font-size: 16px;
}

.card_title {
  font-size: 18px;
  font-weight: 700;
  color: #5E445A;
}

.card_title_progress {
  font-size: 14px;
  font-weight: 700;
  color: #5E445A;
}

.last_update {
  font-size: 10px;
  font-weight: 300;
  color: #5E445A;
}

.card_button {
  outline: none;
  border: none;
  width: 120px;
  height: 38px;
  background: #3A273E;
  border-radius: 6px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  transition: all 0.35s;
  cursor: pointer;
}

.card_button:hover {
  transform: scale(1.05);
}

.data {
  border-top: solid #E6E3ED 2px;
}

.data div {
  height: 100% !important;
  width: 100% !important;
}

.data div:first-child {
  border-right: solid #E6E3ED 1px;
}

.data div:last-child {
  border-left: solid #E6E3ED 1px;
}

.data div p:first-child {
  font-size: 8px;
  font-weight: 300;
  line-height: 10px;
  color: #A4A9AD;
}

.data div p:last-child {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: #A4A9AD;
}
</style>

<template>
  <div class="container">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 gap-x-6">
      <div
        v-for="homeCard in homeCards"
        :key="homeCard.title"
      >
        <card
          :title="homeCard.title"
          :img="homeCard.img"
          :link="homeCard.link"
          :button-text="homeCard.buttonText"
        />
      </div>
    </div>
  </div>
</template>
<script>
import card from "@/views/Components/Card.vue";

export default {
  components: {
    card,
  },
  data() {
    return {
      vueAppClasses: [],
      // ecommerce_orders: 40,
      // total_donations: 200000,
      // new_form: 15,

      // completedSteps: 85,

      // done: 36,
      // on_progress: 4,
      homeCards: [
        {
          title: 'الإعدادات',
          img: require('@//assets/images/icons/home/gearVector.svg'),
          buttonText: 'الذهاب للإعدادات',
          link: '/dev-configuration',
        },
        {
          title: 'متجر التميز الإلكتروني',
          img: require('@//assets/images/icons/home/mallVector.svg'),
          buttonText: 'الذهاب للمتجر',
          link: '/ecommerce/personal',
        },
        {
          title: 'الإستمارات',
          img: require('@//assets/images/icons/home/docVector.svg'),
          buttonText: 'الذهاب للإستمارات',
          link: '/employment-forms',
        },
        {
          title: 'قارئ الباركود',
          img: require('@//assets/images/icons/home/qrVector.svg'),
          buttonText: 'الذهاب للقارئ',
          link: '/barcode',
        },

      ],
    };
  },
  methods: {
    RouteTo(x) {
      this.$router.push({path: x});
    }
  }
};
</script>


<style scoped>


.home_card,
.home_percentage,
.home_table {
  background-color: white;
  min-height: 156px;
  border-radius: 15px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
}


.home_percentage,
.home_table {
  min-height: 233px;
  border-radius: 10px;
}

.content p:first-child {
  font-weight: 700;
  font-size: 24px;
}

.content p:last-child {
  font-weight: 500;
  font-size: 16px;
}

.card_title {
  font-size: 18px;
  font-weight: 700;
  color: #5E445A;
}


.card_title_progress {
  font-size: 14px;
  font-weight: 700;
  color: #5E445A;
}

.last_update {
  font-size: 10px;
  font-weight: 300;
  color: #5E445A;
}


.card_button {
  outline: none;
  border: none;
  width: 120px;
  height: 38px;
  background: #3A273E;
  border-radius: 6px;
  color: white;
  font-weight: 400;
  font-size: 12px;
  transition: all 0.35s;
  cursor: pointer;
  /* font-family: 'Inter', sans-serif !important; */
}

.card_button:hover {
  transform: scale(1.05);
}

.progress-bar-completedSteps {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 30px;
  letter-spacing: 2px;
}

.data {
  border-top: solid #E6E3ED 2px;
}

.data div {
  height: 100% !important;
  width: 100% !important;
}

.data div:first-child {
  border-right: solid #E6E3ED 1px;
}

.data div:last-child {
  border-left: solid #E6E3ED 1px;
}

.data div p:first-child {
  font-size: 8px;
  font-weight: 300;
  line-height: 10px;
  color: #A4A9AD;
}

.data div p:last-child {
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  color: #A4A9AD;
}

</style>
